import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import VDragged from "v-dragged";

Vue.prototype.$http = axios;
Vue.config.productionTip = false;
Vue.use(VDragged);

import "intro.js/introjs.css";
import VueIntro from "vue-introjs";
Vue.use(VueIntro);

Vue.prototype.$forceCompute = function (computedName, forceUpdate) {
  if (this._computedWatchers[computedName]) {
    this._computedWatchers[computedName].run();
    if (forceUpdate || typeof forceUpdate == "undefined") this.$forceUpdate();
  }
};

new Vue({
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
