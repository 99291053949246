<template>
  <div>
    <div v-if="loggedIn">
      <Main />
    </div>
    <div v-if="!loggedIn">
      <v-app id="app">
        <v-snackbar
          :timeout="-1"
          absolute
          bottom
          center
          color="error"
          text
          multiLine
          v-model="showError"
          style="z-index: 10000"
        >
          <div v-if="showError">
            <p>
              <strong>{{ error.error }}</strong>
            </p>
            <p>{{ error.error_description }}</p>
            <p>Please try again.</p>
          </div>
        </v-snackbar>
      </v-app>
      <v-dialog persistent v-model="showLoginPopup" width="300">
        <v-card>
          <v-card-title></v-card-title>
          <v-card-text class="popup-text">
            <v-btn block color="primary" @click="login()">Log in</v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import Main from "@/components/Main";
import config from "./config.json";

export default {
  name: "App",
  components: {
    Main,
  },
  computed: {},
  data: () => ({
    loggedIn: false,
    showLoginPopup: false,
    error: null,
    showError: false,
  }),
  methods: {
    init() {
      const params = new URLSearchParams(location.search);
      const code = params.get("code");
      if (code) {
        this.checkCode(code);
      } else if (window.location.hostname === "localhost") {
        this.loggedIn = true;
      } else {
        this.showLoginPopup = true;
      }
    },
    async checkCode(code) {
      const { tokenPath, redirect_uri, clientID, clientSecret } = config;
      const auth = window.btoa(
        unescape(encodeURIComponent(clientID + ":" + clientSecret))
      );
      const headers = new Headers();
      headers.append("Content-Type", "application/x-www-form-urlencoded");
      headers.append("Authorization", `Basic ${auth}`);

      const urlencoded = new URLSearchParams();
      urlencoded.append("grant_type", "authorization_code");
      urlencoded.append("code", code);
      urlencoded.append("redirect_uri", redirect_uri);

      const requestOptions = {
        method: "POST",
        headers: headers,
        body: urlencoded,
        redirect: "follow",
      };

      console.log({ tokenPath, requestOptions });

      const response = await fetch(tokenPath, requestOptions);
      const result = await response.json();
      console.log({ response, result });
      if (response.ok) {
        this.checkToken(result);
      } else if (result.error === "invalid_grant") {
        this.login();
      } else {
        this.error = result;
        this.showError = true;
        this.showLoginPopup = true;
      }
    },
    checkToken(token) {
      if (!token || (!token.error && token.access_token)) {
        this.error = null;
        this.loggedIn = true;
      } else {
        this.error = token.error;
      }
    },
    login() {
      const { authPath, clientID, redirect_uri } = config;
      const url = `${authPath}?response_type=code&client_id=${clientID}&redirect_uri=${redirect_uri}&scope=openid profile`;
      location.href = url;
    },
  },
  created() {
    this.init();
  },
  mounted() {
    window.$app = this;
  },
  unmounted() {},
};
</script>

<style lang="scss">
html {
  overflow-y: hidden !important;
}
.v-application .v-autocomplete__content:first-of-type {
  display: none !important;
}
.v-application.allow-ac .v-autocomplete__content:first-of-type {
  display: block !important;
}
</style>
