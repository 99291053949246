<template>
  <div>
    <div id="study-details" v-if="ready">
      <v-row ref="row1" :class="takingImage ? 'd-block' : ''">
        <study-details-card
          prop="Name of study"
          :colClass="takingImage ? 'c-1' : ''"
        ></study-details-card>
      </v-row>
      <v-row ref="row2" :class="takingImage ? 'd-block' : ''">
        <study-details-card
          :colClass="takingImage ? 'c-3' : ''"
          title="Planned Enrollment"
          prop="Planned Enrollment (n)"
          center="true"
        ></study-details-card>
        <study-details-card
          :colClass="takingImage ? 'c-3' : ''"
          title="Actual Enrollment"
          prop="Actual Enrollment"
          center="true"
        ></study-details-card>
        <study-details-card
          :colClass="takingImage ? 'c-3' : ''"
          prop="Study type"
          center="true"
        ></study-details-card>
      </v-row>
      <v-row ref="row2b" :class="takingImage ? 'd-block' : ''">
        <study-details-card
          :colClass="takingImage ? 'c-3' : ''"
          prop="Regional ownership"
          center="true"
        ></study-details-card>
        <study-details-card
          :colClass="takingImage ? 'c-3' : ''"
          prop="Functional ownership"
          center="true"
        ></study-details-card>
        <study-details-card
          :colClass="takingImage ? 'c-3' : ''"
          prop="ReCap number"
          center="true"
        ></study-details-card>
      </v-row>
      <v-row ref="row3" :class="takingImage ? 'd-block' : ''">
        <study-details-card
          :colClass="takingImage ? 'c-2' : ''"
          title="Investigator"
          prop="Principal Investigator"
          center="true"
        ></study-details-card>
        <study-details-card
          :colClass="takingImage ? 'c-2' : ''"
          prop="Institution"
          center="true"
        ></study-details-card>
      </v-row>
      <v-row ref="row4" :class="takingImage ? 'd-block' : ''">
        <v-col :class="takingImage ? 'c-2' : ''">
          <v-row>
            <study-details-card
              colClass="mx-0 px-0"
              prop="Study Design"
              center="true"
            ></study-details-card>
          </v-row>
          <v-row class="mb-0 pb-0">
            <study-details-card
              colClass="mb-0 pb-0 mx-0 px-0"
              title="Primary endpoints"
              prop="Primary endpoint"
            ></study-details-card>
          </v-row>
        </v-col>
        <study-details-card
          :colClass="takingImage ? 'c-2' : ''"
          prop="Primary Objective"
          growContent="true"
        ></study-details-card>
      </v-row>
      <v-row ref="row5" :class="takingImage ? 'd-block' : ''">
        <study-details-card
          :colClass="takingImage ? 'c-3' : ''"
          prop="Status"
          center="true"
        ></study-details-card>
        <v-col :class="takingImage ? 'c-3' : ''">
          <div class="nct growContent" v-if="nct">
            <a
              :href="`https://clinicaltrials.gov/ct2/show/${nct}`"
              target="_blank"
              >{{ nct }}</a
            >
          </div>
        </v-col>
        <study-details-card
          :colClass="takingImage ? 'c-3' : ''"
          prop="Anticipated year of first publication"
          center="true"
        ></study-details-card>
      </v-row>
      <v-row class="mt-2 text-right" v-if="!takingImage">
        <v-col cols="4">
          <v-btn dark color="#00a0de" @click="email()">
            <v-icon class="mr-2">mdi-email</v-icon>
            <strong>SEND UPDATES</strong>
          </v-btn>
        </v-col>
        <v-col cols="7">
          <v-btn dark color="orange" block @click="saveImage()">
            <v-icon class="mr-2">mdi-download</v-icon>
            <strong>DOWNLOAD</strong>
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import StudyDetailsCard from "@/components/StudyDetailsCard";
import { saveAs } from "file-saver";
import domToImage from "dom-to-image";

const EMAIL = {
  to: "evigens@lumanity.com ",
  subject: "EviGens Content Update Request [COMMON_NAME]",
  body: "Please see my comments below to update [COMMON_NAME]",
};

export default {
  name: "StudyDetails",
  props: ["study"],
  components: {
    StudyDetailsCard,
  },
  data() {
    return {
      ready: true,
      nct: null,
      takingImage: false,
    };
  },
  watch: {
    study: function () {
      this.ready = false;
      setTimeout(this.init, 25);
    },
  },
  mounted() {
    window.$sd = this;
    this.init();
  },
  methods: {
    init() {
      this.nct = this.study.data["NCT number"];
      this.ready = true;
      setTimeout(this.setRowHeights, 25);
    },
    sd(prop) {
      if (!this.study || !this.study.data) {
        return "&nbsp;";
      } else {
        const VAL = this.study.data[prop];
        if (VAL === undefined || VAL === null || VAL === "") {
          return "&nbsp;";
        } else {
          return VAL;
        }
      }
    },
    setRowHeights() {
      for (let row in this.$refs) {
        this.setRowHeight(row);
      }
    },
    setRowHeight(row) {
      let els = this.$refs[row].children;
      let headerHeight = 0;
      let contentHeight = 0;
      for (let i = 0; i < els.length; i++) {
        let el = els[i];
        let header = el.querySelectorAll(".sd-card-title")[0];
        let content = el.querySelectorAll(".sd-card-content")[0];
        if (header && content) {
          header.style.height = "";
          content.style.height = "";
          if (content.offsetHeight > contentHeight) {
            contentHeight = content.offsetHeight;
          }
          if (header.offsetHeight > headerHeight) {
            headerHeight = header.offsetHeight;
          }
        }
      }
      for (let i = 0; i < els.length; i++) {
        let el = els[i];
        let header = el.querySelectorAll(".sd-card-title")[0];
        let content = el.querySelectorAll(".sd-card-content")[0];
        if (header && content) {
          header.style.height = headerHeight + "px";
          content.style.height = contentHeight + "px";
        }
      }
      this.checkForGrowHeight(row);
    },
    checkForGrowHeight(rowId) {
      let row = this.$refs[rowId];
      let grower = row.querySelectorAll(".growContent");
      if (grower.length) {
        let gr_el = grower[0];
        let titleHeight = 0;
        gr_el.style.height = "";
        let title_els = gr_el.parentElement.querySelectorAll(".sd-card-title");
        if (title_els.length) {
          titleHeight = title_els[0].offsetHeight;
        }
        let rowHeight = row.offsetHeight;
        gr_el.style.height = rowHeight - titleHeight - 5 + "px";
      }
    },
    saveImage() {
      this.takingImage = true;
      window.$main.hideClose = true;
      const node = document.getElementById("study-card");
      const filename =
        this.study.data["Common Name (Investigator)"].replace(
          /[^a-zA-Z0-9]/gi,
          "_"
        ) + ".png";
      domToImage
        .toPng(node)
        .then((dataUrl) => {
          this.takingImage = false;
          window.$main.hideClose = false;
          saveAs(dataUrl, filename);
        })
        .catch((error) => {
          console.error("oops, something went wrong!", error);
        });
    },
    email() {
      let mail = `mailto:${EMAIL.to}?subject=${EMAIL.subject}&body=${EMAIL.body}`;
      mail = mail
        .split("[COMMON_NAME]")
        .join(this.study.data["Common Name (Investigator)"]);
      window.open(mail, "_self");
    },
  },
};
</script>

<style lang="scss" scoped>
#study-details {
  .row {
    margin: 0;
    padding: 0;
  }
  .col {
    margin: 0 2px 5px;
    padding: 0;
  }
  .sd-card {
    border-radius: 8px 8px 0 0;
    background-color: white;
    .sd-card-title {
      width: 100%;
      background-color: #003479;
      border-radius: 8px 8px 0 0;
      line-height: 125%;
      color: white;
      text-transform: uppercase;
      text-align: center;
      font-size: 12px;
      font-weight: 500;
      padding: 8px 4px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .sd-card-content {
      padding: 8px;
      font-size: 15px;
      line-height: 115%;
      color: black;
    }
  }
  .nct {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.c-1 {
  width: 100% !important;
  display: block !important;
  flex-grow: revert !important;
}
.c-2 {
  width: calc(50% - 4px) !important;
  display: inline-block !important;
  flex-grow: revert !important;
}
.c-3 {
  width: calc(33.333% - 4px) !important;
  display: inline-block !important;
  flex-grow: revert !important;
}
</style>
