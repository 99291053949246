import axios from "axios";

const colors = ["#5584AC", "#95D1CC", "#F6F2D4", "#72a7cE", "#D5E1FF"];
const scale = [10, 40, 160, 640, 2560, 10240, 999999999];
const TABLE_DATA = [
  "Overarching disease state",
  "Specific disease segment",
  "Imaging Used (Conventional or NGI)",
  "Name of study",
  "Common Name (Investigator)",
  "Planned Enrollment (n)",
  "Actual Enrollment",
  "Study type",
  "Regional ownership",
  "Country ownership",
  "Functional ownership",
  "Study Design",
  "Key Research Question",
  "Key Research Question 2",
  "Key Research Question 3",
  "Key Research Question 4",
  "Key Research Question 5",
  "Anticipated year of first publication",
  "Principal Investigator",
  "Institution",
  "Status",
  "Primary endpoint",
  "Secondary endpoint",
  "Treatment Arms",
  "Phase",
  "Primary Objective",
  "ReCap approval date",
  "ReCap number",
  "ReCap link",
  "JJAR ID number",
  "Protocol Number/Janssen Study Number",
  "NCT link",
  "NCT number",
];
let uniqueId = 0;
let rawData = [];

function getSize(num) {
  const INDEX = scale.findIndex((el) => el > num);
  const SIZE = 5 + INDEX * 3;
  // console.log(num, INDEX, SIZE);
  return SIZE;
}

function getUniqueId() {
  uniqueId++;
  return uniqueId;
}

export default {
  loadData: loadData,
  colors: colors,
  decodeHtml: decodeHtml,
  tableData: TABLE_DATA,
};

async function loadData() {
  const res = await axios("./data.json");
  let data = res.data;
  if (typeof data === "string") {
    data = JSON.parse(data);
  }
  const studies = getStudies(data);
  const assets = getAssets(studies);
  const diseaseStates = getDiseaseStates(studies);
  const keyQuestions = getKeyQuestions(studies);
  const studyDesigns = getStudyDesigns(studies);
  const studyTypes = getStudyTypes(studies);
  const regions = getRegions(studies);
  const owners = getOwners(studies);
  const loadedData = {
    studies,
    assets,
    diseaseStates,
    keyQuestions,
    studyTypes,
    studyDesigns,
    regions,
    owners,
    tableData: TABLE_DATA,
  };
  console.log(loadedData);
  return loadedData;
}

function getStudies(raw) {
  const studies = [];
  raw.forEach((item, i) => {
    item.id = i;
    const UNIQUE_ID = getUniqueId();
    const STUDY = {
      uniqueId: UNIQUE_ID,
      type: "study",
      asset: item.Asset.toLowerCase(),
      name: item["Name of study"],
      size: getSize(item.N),
      studyTypes: splitAndTrim(item["Study type"]),
      diseaseStates: splitAndTrim(item["Overarching disease state"]),
      data: item,
      tableData: {},
    };
    STUDY.data.uniqueId = UNIQUE_ID;
    TABLE_DATA.forEach((prop) => {
      STUDY.tableData[prop] = item[prop];
    });
    rawData[UNIQUE_ID] = STUDY;
    studies[i] = STUDY;
  });
  return studies;
}

function getAssets(studies) {
  const capitalize = (s) => s && s[0].toUpperCase() + s.slice(1);
  const list = [];
  const data = {};
  studies.forEach((study) => {
    const assets = study.data["Asset"].split(",");
    assets.forEach((asset) => {
      asset = capitalize(asset.trim());
      if (asset) {
        if (!data[asset]) {
          list.push(asset);
          data[asset] = [];
        }
        data[asset].push(study.uniqueId);
      }
    });
  });
  list.sort();
  return { list, data };
}

function getDiseaseStates(studies) {
  const states = {};
  studies.forEach((study) => {
    const studyStates = study.diseaseStates || [];
    studyStates.forEach((state) => {
      if (!states[state]) {
        states[state] = [];
      }
      states[state].push(study.uniqueId);
    });
  });
  return states;
}

function getStudyDesigns(studies) {
  const list = [];
  const data = {};
  studies.forEach((study) => {
    let studyDesigns = [];
    if (study.data["Study Design"]) {
      studyDesigns = study.data["Study Design"].split(",");
    }
    studyDesigns.forEach((studyDesign) => {
      studyDesign = studyDesign.trim();
      if (!data[studyDesign]) {
        list.push(studyDesign);
        data[studyDesign] = [];
      }
      data[studyDesign].push(study.uniqueId);
    });
  });
  list.sort();
  return { list, data };
}

function getStudyTypes(studies) {
  const list = [];
  const data = {};
  studies.forEach((study) => {
    let studyTypes = [];
    if (study.data["Study type"]) {
      studyTypes = study.data["Study type"].split(",");
    }
    studyTypes.forEach((studyType) => {
      studyType = studyType.trim();
      if (!data[studyType]) {
        list.push(studyType);
        data[studyType] = [];
      }
      data[studyType].push(study.uniqueId);
    });
  });
  list.sort();
  return { list, data };
}

function getRegions(studies) {
  const list = [];
  const data = {};
  studies.forEach((study) => {
    let regions = [];
    if (study.data["Regional ownership"]) {
      regions = study.data["Regional ownership"].split(",");
    }
    regions.forEach((region) => {
      region = region.trim();
      if (!data[region]) {
        list.push(region);
        data[region] = [];
      }
      data[region].push(study.uniqueId);
    });
  });
  list.sort();
  return { list, data };
}

function getOwners(studies) {
  const list = [];
  const data = {};
  studies.forEach((study) => {
    let owners = [];
    if (study.data["Functional ownership"]) {
      owners = study.data["Functional ownership"].split(",");
    }
    owners.forEach((owner) => {
      owner = decodeHtml(owner.trim());
      if (!data[owner]) {
        list.push(owner);
        data[owner] = [];
      }
      data[owner].push(study.uniqueId);
    });
  });
  list.sort();
  return { list, data };
}

function getKeyQuestions(studies) {
  const list = [];
  const data = {};
  function addQuestion(question, id) {
    if (question) {
      question = question.trim();
      if (!list[question]) {
        list.push(question);
        data[question] = [];
      }
      data[question].push(id);
    }
  }
  studies.forEach((study) => {
    const id = study.uniqueId;
    addQuestion(study.data["Key Research Question"], id);
    addQuestion(study.data["Key Research Question 2"], id);
    addQuestion(study.data["Key Research Question 3"], id);
    addQuestion(study.data["Key Research Question 4"], id);
    addQuestion(study.data["Key Research Question 5"], id);
  });
  return { list, data };
}

function splitAndTrim(str) {
  if (str === null) {
    return [];
  }
  try {
    const ARR = str.split(",");
    ARR.forEach((val, i) => {
      // ARR[i] = val.trim().toLowerCase();
      ARR[i] = decodeHtml(val.trim());
    });
    return ARR;
  } catch (e) {
    console.log({ str });
    console.error(e);
    return [];
  }
}

function decodeHtml(html) {
  var txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
}
