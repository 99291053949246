<template>
  <div>
    <v-app id="app">
      <v-toolbar id="header-bar" elevation="1">
        <div id="disabled" style="display: none">
          <v-btn text @click="resetZoom()">
            <v-icon>mdi-home</v-icon>
          </v-btn>
          <v-divider vertical v-if="asset" class="mx-3"></v-divider>
          <v-btn
            text
            v-if="asset"
            @click="zoom(asset.uniqueId, 'asset')"
            v-html="asset.name"
          ></v-btn>
          <v-divider vertical v-if="diseaseState" class="mx-3"></v-divider>
          <v-btn
            text
            v-if="diseaseState"
            @click="zoom(diseaseState.uniqueId, 'diseaseState')"
            v-html="diseaseState.name"
          ></v-btn>
          <v-divider vertical v-if="studyType" class="mx-3"></v-divider>
          <v-btn
            text
            v-if="studyType"
            @click="zoom(studyType.uniqueId, 'studyType')"
            v-html="studyType.name"
          ></v-btn>
          <v-spacer></v-spacer>
          <v-text-field
            elevation="0"
            regular
            style="max-width: 320px"
            label="Search..."
            append-icon="mdi-magnify"
            hide-details
          ></v-text-field>
        </div>
        <div id="left-logo"></div>
        <v-spacer></v-spacer>
        <div v-intro="intro.buttons">
          <v-btn
            color="#233a75"
            @click="showPopup('Abbreviations')"
            v-if="!isMobile"
            text
          >
            Abbreviations
          </v-btn>
          <v-btn
            color="#233a75"
            @click="showPopup('Overview')"
            v-if="!isMobile"
            text
            class="mr-4"
          >
            Overview
          </v-btn>
        </div>
        <div id="right-logo"></div>
      </v-toolbar>
      <v-toolbar
        id="filter-bar"
        elevation="0"
        dark
        v-if="ready && !isMobile"
        v-intro="intro.filter"
      >
        <v-row>
          <v-col>
            <v-autocomplete
              :search-input.sync="filters.search"
              :items="acItems"
              @change="
                hideAc();
                updateSearch();
              "
              @keydown="checkAc()"
              @blur="hideAc()"
              dark
              dense
              hide-details
              no-data-text="No studies match that search"
              clearable
              label="Search"
              class="pt-8"
            ></v-autocomplete>
          </v-col>
          <v-col>
            <v-combobox
              v-model="filters.assets"
              :items="data.assets.list"
              label="Assets"
              class="pt-8"
              @change="updateSearch()"
              multiple
              dense
              small-chips
            >
            </v-combobox>
          </v-col>
          <v-col>
            <v-combobox
              v-model="filters.studyDesigns"
              :items="data.studyDesigns.list"
              label="Study Designs"
              class="pt-8"
              @change="updateSearch()"
              multiple
              dense
              small-chips
            >
            </v-combobox>
          </v-col>
          <v-col>
            <v-combobox
              v-model="filters.regions"
              :items="data.regions.list"
              label="Regions"
              class="pt-8"
              @change="updateSearch()"
              multiple
              dense
              small-chips
            >
            </v-combobox>
          </v-col>
          <v-col>
            <v-combobox
              v-model="filters.studyTypes"
              :items="data.studyTypes.list"
              label="Study Types"
              class="pt-8"
              @change="updateSearch()"
              multiple
              dense
              small-chips
            >
            </v-combobox>
          </v-col>
          <v-col>
            <v-combobox
              v-model="filters.owners"
              :items="data.owners.list"
              label="Functional Ownership"
              class="pt-8"
              @change="updateSearch()"
              multiple
              dense
              small-chips
            ></v-combobox>
          </v-col>
        </v-row>
      </v-toolbar>
      <v-toolbar
        id="filter-bar"
        elevation="0"
        dark
        v-if="ready && isMobile"
        v-intro="intro.filter"
      >
        <v-btn dark text @click="showFilters = true">
          <v-icon>mdi-filter</v-icon> Filters
        </v-btn>
        <v-spacer></v-spacer>
        <v-menu dark bottom left offset-x>
          <template v-slot:activator="{ on, attrs }">
            <v-btn dark icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="showPopup('Abbreviations')">
              <v-list-item-title>Abbreviations</v-list-item-title>
            </v-list-item>
            <v-list-item @click="showPopup('Overview')">
              <v-list-item-title>Overview</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>
      <v-main v-if="ready">
        <v-container>
          <v-row dense id="main">
            <v-col id="left">
              <div id="bubble-contents" ref="zoomWindow">
                <div
                  id="bubble-trace"
                  :style="btStyle"
                  v-intro="intro.main"
                ></div>
                <div
                  class="bc-box bc-box-1"
                  ref="LPC"
                  :class="getBoxClass('LPC')"
                  :style="getBoxStyle('LPC')"
                  @click="boxClicked('LPC')"
                >
                  <div class="bc-box-contents">
                    LPC<br />({{ getCount("LPC") }})
                  </div>
                </div>
                <div
                  class="bc-box bc-box-2"
                  ref="BCR"
                  :class="getBoxClass('BCR')"
                  :style="getBoxStyle('BCR')"
                  @click="boxClicked('BCR')"
                >
                  <div class="bc-box-contents">
                    BCR<br />({{ getCount("BCR") }})
                  </div>
                </div>
                <div class="bc-box-3">
                  <div>
                    <div
                      class="bc-box bc-box-3a"
                      ref="mCSPC"
                      :class="getBoxClass('mCSPC')"
                      :style="getBoxStyle('mCSPC')"
                      @click="boxClicked('mCSPC')"
                    >
                      <div class="bc-box-contents">
                        mCSPC<br />({{ getCount("mCSPC") }})
                      </div>
                    </div>
                  </div>
                  <div>
                    <div
                      class="bc-box bc-box-3b"
                      ref="nmCRPC"
                      :class="getBoxClass('nmCRPC')"
                      :style="getBoxStyle('nmCRPC')"
                      @click="boxClicked('nmCRPC')"
                    >
                      <div class="bc-box-contents">
                        nmCRPC<br />({{ getCount("nmCRPC") }})
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="bc-box bc-box-4"
                  ref="mCRPC"
                  :class="getBoxClass('mCRPC')"
                  :style="getBoxStyle('mCRPC')"
                  @click="boxClicked('mCRPC')"
                >
                  <div class="bc-box-contents">
                    mCRPC<br />({{ getCount("mCRPC") }})
                  </div>
                </div>
                <div id="bars" v-show="showBars">
                  <div id="bar1"></div>
                  <div id="bar2" class="diag-up"></div>
                  <div id="bar3" class="diag-down"></div>
                  <div id="bar4" class="diag-down"></div>
                  <div id="bar5" class="diag-up"></div>
                </div>
                <div
                  id="study-list"
                  :style="
                    filters.diseaseState
                      ? 'opacity: 1;'
                      : 'pointer-events: none;'
                  "
                >
                  <v-btn
                    dark
                    large
                    icon
                    id="study-list-close"
                    @click="resetState"
                  >
                    <v-icon>mdi-arrow-left-circle</v-icon>
                  </v-btn>
                  <div id="breadcrumbs">
                    {{ filters.diseaseState }} ({{
                      getCount(filters.diseaseState)
                    }})
                  </div>
                  <div id="study-list-content">
                    <div
                      @click="tableRowClicked(s)"
                      :class="tableRowClass(s)"
                      class="study-card"
                      v-for="(s, i) in tableData"
                      :key="i"
                    >
                      <span class="type" v-html="s['Study type']"></span>
                      <span
                        class="name"
                        v-html="
                          s['Common Name (Investigator)'] || s['Name of study']
                        "
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
          <div id="bottom-buttons">
            <div v-intro="intro.download">
              <v-btn dark fab small id="help-btn" @click="showHelp()">
                <v-icon>mdi-help</v-icon>
              </v-btn>
              <v-btn
                dark
                fab
                small
                id="table-settings"
                @click="showTableSettings = true"
              >
                <v-icon>mdi-cog</v-icon>
              </v-btn>
              <v-btn
                dark
                fab
                small
                id="table-download"
                @click="downloadTable()"
              >
                <v-icon>mdi-table-arrow-down</v-icon>
              </v-btn>
            </div>
          </div>
          <v-row dense id="bottom" v-intro="intro.table">
            <v-data-table
              id="bottom-data-table"
              fixed-header
              dense
              :height="tableHeight"
              :headers="headers"
              :items="tableData"
              :items-per-page="-1"
              hide-default-footer
              class="elevation-1"
              mobile-breakpoint="0"
            >
              <template v-slot:body="{ items, headers }">
                <tbody>
                  <tr
                    v-for="(item, idx) in items"
                    :key="idx"
                    @click="tableRowClicked(item)"
                    :class="tableRowClass(item)"
                  >
                    <td
                      v-for="(header, key) in headers"
                      :key="key"
                      :style="`max-width: 100px;`"
                    >
                      <div v-html="item[header.value]"></div>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </v-row>
        </v-container>
      </v-main>
    </v-app>

    <v-dialog
      v-model="popup.show"
      width="800"
      :fullscreen="isMobile"
      :hide-overlay="isMobile"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-title class="popup-title">
          <span v-html="popup.title"></span>
          <v-spacer></v-spacer>
          <v-btn text icon dark @click="popup.show = false">
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="popup-text">
          <div :class="isMobile ? 'mobile-scroller scroller' : 'scroller'">
            <div v-html="popup.content"></div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showTableSettings" width="800">
      <v-card>
        <v-card-title class="popup-title">
          <span>Table Columns</span>
          <v-spacer></v-spacer>
          <v-btn text icon dark @click="showTableSettings = false">
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="popup-text">
          <v-checkbox
            v-for="(header, i) in tableColumns"
            :key="'header_' + i"
            @change="updateHeaders()"
            v-model="header._enabled"
            dense
            class="ma-0 pa-0 inline-checkbox"
            hide-details
            :label="header.text"
          ></v-checkbox>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="studyPopup" width="800">
      <v-card v-if="study" id="study-card">
        <v-card-title class="popup-title">
          <span
            :class="hideClose ? 'full-width' : ''"
            v-if="study.data"
            v-html="study.data['Common Name (Investigator)']"
          ></span>
          <v-spacer v-if="!hideClose"></v-spacer>
          <v-btn text icon dark @click="closeStudyPopup()" v-if="!hideClose">
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="popup-text">
          <study-details :study="study"></study-details>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showFilters" width="800">
      <v-card dark v-if="showFilters">
        <v-card-title class="popup-title">
          <div><v-icon>mdi-filter</v-icon> Filters</div>
          <v-spacer></v-spacer>
          <v-btn text icon dark @click="showFilters = false">
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="popup-text">
          <v-autocomplete
            v-model="filters.search"
            :items="acItems"
            @change="updateSearch()"
            dark
            dense
            hide-details
            clearable
            label="Search"
            class="pt-8"
          ></v-autocomplete>
          <v-combobox
            v-model="filters.assets"
            :items="data.assets.list"
            label="Assets"
            class="pt-8"
            @change="updateSearch()"
            multiple
            dense
            small-chips
          >
          </v-combobox>
          <v-combobox
            v-model="filters.studyDesigns"
            :items="data.studyDesigns.list"
            label="Study Designs"
            class="pt-8"
            @change="updateSearch()"
            multiple
            dense
            small-chips
          >
          </v-combobox>
          <v-combobox
            v-model="filters.regions"
            :items="data.regions.list"
            label="Regions"
            class="pt-8"
            @change="updateSearch()"
            multiple
            dense
            small-chips
          >
          </v-combobox>
          <v-combobox
            v-model="filters.studyTypes"
            :items="data.studyTypes.list"
            label="Study Types"
            class="pt-8"
            @change="updateSearch()"
            multiple
            dense
            small-chips
          >
          </v-combobox>
          <v-combobox
            v-model="filters.owners"
            :items="data.owners.list"
            label="Functional Ownership"
            class="pt-8"
            @change="updateSearch()"
            multiple
            dense
            small-chips
          >
          </v-combobox>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import DataService from "@/services/dataService";
import StudyDetails from "@/components/StudyDetails";
import introJs from "intro.js/intro.js";
import help from "@/help.json";

const FILTERS = {
  search: "",
  studyDesigns: [],
  assets: [],
  regions: [],
  studyTypes: [],
  owners: [],
  diseaseState: "",
};

const COLORS = {
  LPC: "#6EBD44",
  BCR: "#6F2E38",
  mCRPC: "#1C75BC",
  nmCSPC: "#00A0DF",
  mCSPC: "#003479",
};

const POPUP_CONTENT = {
  glossary:
    "<ul><li>Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</li><li>Aliquam tincidunt mauris eu risus.</li><li>Vestibulum auctor dapibus neque.</li></ul>",
  abbreviations: `
    <ul>
      <li><strong>AAP:</strong> abiraterone acetate + prednisone/prednisolone</li>
      <li><strong>ADT:</strong> androgen deprivation therapy</li>
      <li><strong>AE:</strong> adverse event</li>
      <li><strong>APA:</strong> apalutamide</li>
      <li><strong>APAC:</strong> Asia-Pacific Region</li>
      <li><strong>AR:</strong> androgen receptor</li>
      <li><strong>BCR:</strong> biochemical recurrence</li>
      <li><strong>BER:</strong> base excision repair</li>
      <li><strong>BRCA1:</strong> DNA repair associated breast cancer-1 gene </li>
      <li><strong>BRCA2:</strong> DNA repair associated breast cancer-2 gene </li>
      <li><strong>CDx:</strong> companion diagnostic </li>
      <li><strong>CR:</strong> complete response </li>
      <li><strong>CRPC:</strong> castration-resistant prostate cancer</li>
      <li><strong>CS:</strong> collaborative study</li>
      <li><strong>CSPC:</strong> castration-sensitive prostate cancer</li>
      <li><strong>CSR:</strong> Clinical Study Report</li>
      <li><strong>CSS:</strong> company-sponsored study</li>
      <li><strong>CTC:</strong> circulating tumor cell </li>
      <li><strong>DAT:</strong> dual action tablet (ie, niraparib + AA)</li>
      <li><strong>DFS:</strong> disease-free survival</li>
      <li><strong>DLT:</strong> dose-limiting toxicity </li>
      <li><strong>DNA-</strong>PKcs: DNA-protein kinase catalytic subunit</li>
      <li><strong>DRD:</strong> DNA repair gene deficiency</li>
      <li><strong>DSB:</strong> double-strand break</li>
      <li><strong>EBRT:</strong> external-beam radiotherapy</li>
      <li><strong>ECOG </strong>PS: Eastern Cooperative Oncology Group performance status </li>
      <li><strong>EMEA:</strong> Europe, Middle East, Africa region</li>
      <li><strong>FACT-</strong>P: Functional Assessment of Cancer Therapy-Prostate </li>
      <li><strong>FDC:</strong> fixed-dose combination </li>
      <li><strong>FFS:</strong> failure-free survival</li>
      <li><strong>GG:</strong> Gleason grade group</li>
      <li><strong>GS:</strong> Gleason score</li>
      <li><strong>HEMAR:</strong> health economics and market access research</li>
      <li><strong>HR:</strong> homologous recombination</li>
      <li><strong>HRQoL:</strong> health-related quality of life </li>
      <li><strong>HRR:</strong> homologous recombination repair</li>
      <li><strong>HRU:</strong> health resource utilization</li>
      <li><strong>IEGP:</strong> Internal Evidence Generation Planning</li>
      <li><strong>IIS:</strong> investigator-initiated study</li>
      <li><strong>IMRT:</strong> intensity-modulated radiation therapy</li>
      <li><strong>IR:</strong> ionizing radiation </li>
      <li><strong>ITT:</strong> intent to treat</li>
      <li><strong>L1:</strong> line 1</li>
      <li><strong>LDT:</strong> laboratory-derived test</li>
      <li><strong>LHRH:</strong> luteinizing hormone-releasing hormone </li>
      <li><strong>LPC:</strong> localized prostate cancer </li>
      <li><strong>M1:</strong> first instance of metastasis</li>
      <li><strong>MA:</strong> Market Access</li>
      <li><strong>MAF:</strong> Medical Affairs</li>
      <li><strong>mCRPC:</strong> metastatic castration-resistant prostate cancer</li>
      <li><strong>mCSPC:</strong> metastatic castration-sensitive prostate cancer </li>
      <li><strong>MFS:</strong> metastasis-free survival</li>
      <li><strong>mHNPC:</strong> metastatic hormone-naiive prostate cancer</li>
      <li><strong>mHSPC:</strong> metastatic hormone-sensitive prostate cancer</li>
      <li><strong>MRD:</strong> minimal residual disease</li>
      <li><strong>MRU:</strong> medical resource utlization</li>
      <li><strong>NCCN:</strong> National Comprehensive Cancer Network </li>
      <li><strong>NGI:</strong> next-generation imaging</li>
      <li><strong>NHEJ:</strong> non-homologous end joining</li>
      <li><strong>NIRA:</strong> Niraparib</li>
      <li><strong>nmCRPC:</strong> non-metastatic castration-resistant prostate cancer</li>
      <li><strong>ORR:</strong> objective response rate </li>
      <li><strong>OS:</strong> overall survival</li>
      <li><strong>PAR:</strong> poly(adenosine diphosphate-ribose)</li>
      <li><strong>PARP:</strong> poly(adenosine diphosphate-ribose) polymerase</li>
      <li><strong>PC:</strong> prostate cancer </li>
      <li><strong>PCR:</strong> pathological complete response</li>
      <li><strong>PE:</strong> pulmonary embolism</li>
      <li><strong>PFS:</strong> progression-free survival</li>
      <li><strong>PLND:</strong> pelvic lymph node dissection </li>
      <li><strong>pMRD:</strong> pathological minimal residual disease</li>
      <li><strong>PORT:</strong> prostate-only radiation therapy</li>
      <li><strong>PR:</strong> partial response </li>
      <li><strong>pRR:</strong> pathologic response rate</li>
      <li><strong>PSA:</strong> prostate-specific antigen</li>
      <li><strong>RCB:</strong> residual cancer burden</li>
      <li><strong>RCT:</strong> randomized clinical trial</li>
      <li><strong>RECIST:</strong> Response Evaluation Criteria In Solid Tumors</li>
      <li><strong>RP:</strong> radical prostatectomy </li>
      <li><strong>RP2D:</strong> recommended phase 2 dose </li>
      <li><strong>rPFS:</strong> radiographic progression-free survival</li>
      <li><strong>RT:</strong> radiation therapy </li>
      <li><strong>RWD:</strong> real world data</li>
      <li><strong>RWE:</strong> real-world evidence</li>
      <li><strong>RWV&</strong>E: real world value and evidence</li>
      <li><strong>SCP:</strong> scientific communication platform</li>
      <li><strong>SLR:</strong> systematic literature review</li>
      <li><strong>SOC:</strong> standard of care</li>
      <li><strong>SOP:</strong> standard operating procedure</li>
      <li><strong>SRE:</strong> skeletal-related event</li>
      <li><strong>SSB:</strong> single-strand break</li>
      <li><strong>TEAE:</strong> treatment-emergent adverse events </li>
      <li><strong>TTF:</strong> time to treatment failure</li>
    </ul>
  `,
  overview: `
    <p>
      EviGens is an Evidence Generation Study Tool that allows you to identify
      on-going global and regional studies aimed to address key research questions
      in Prostate Cancer, inclusive of those that are company-sponsored,
      collaborative, and investigator-initiated. The tool allows you to search and
      filter by study characteristics, including but not limited to associated
      Janssen asset, prostate cancer disease state, study type, and functional
      ownership. The goal of this tool is to help all internal Janssen stakeholders
      better understand the number and types of evidence generation activities that
      are being conducted around the globe to support integrated evidence generation
      planning (IEGP), as well as to serve as a resource for viewing high-level
      study details. Depending on your needs, you can download a detailed view of
      all the studies that match your search criteria into a spreadsheet or drill
      down to the details associated with a single study. This tool is for INTERNAL
      USE ONLY.
    </p>

  `,
};

const DEFAULT_HEADERS = [
  { text: "Asset", value: "Asset" },
  { text: "Disease State", value: "Overarching disease state" },
  { text: "Study Design", value: "Study Design" },
  { text: "Region", value: "Regional ownership" },
  { text: "Ownership", value: "Functional ownership" },
  { text: "NCT number", value: "NCT number" },
  { text: "Common Name (Investigator)", value: "Common Name (Investigator)" },
];

export default {
  name: "App",
  components: {
    StudyDetails,
  },
  computed: {
    tableHeight() {
      if (!document.getElementById("bottom")) {
        return "80px";
      }
      const HT =
        // document.getElementById("bottom").offsetHeight -
        // document.querySelectorAll("#bottom thead")[0].offsetHeight -
        // 10 +
        // 34;
        document.getElementById("bottom").offsetHeight;
      return `${HT}px`;
    },
    isMobile() {
      return screen.width < 800;
    },
  },
  data: () => ({
    data: [],
    acItems: [],
    tableData: [],
    stateData: {},
    colors: COLORS,
    ht: 600,
    wd: 600,
    outerHeight: 640,
    ready: false,
    chartReady: false,
    asset: null,
    diseaseState: null,
    studyType: null,
    study: null,
    studyPopup: false,
    keyInfo: [
      "Anticipated year of first publication",
      "Primary endpoint",
      "Functional ownership",
    ],
    filters: FILTERS,
    navFilters: {
      asset: "",
      diseaseState: "",
    },
    headers: [],
    keyQuestions: [],
    isDragging: false,
    filtersOpen: false,
    filtersLeft: 0,
    dragStart: 0,
    animTimeout: null,
    topBubbleMode: "",
    searchDebounceTimeout: null,
    maxStateCount: 0,
    showBars: true,
    showTableSettings: false,
    tableColumns: [],
    popup: {
      show: false,
      title: "",
      content: "",
    },
    showFilters: false,
    introJS: introJs(),
    intro: help,
    btStyle: "",
    hideClose: false,
  }),
  created() {
    this.loadData();
  },
  mounted() {
    window.addEventListener("resize", this.resize);
    document.getElementById("app").classList.remove("allow-ac");
    window.$main = this;
    this.init();
  },
  unmounted() {
    window.removeEventListener("resize", this.resize);
  },
  methods: {
    checkAc() {
      setTimeout(() => {
        const val = this.filters.search || "";
        console.log(val);
        if (val.length > 2) {
          document.getElementById("app").classList.add("allow-ac");
        } else {
          document.getElementById("app").classList.remove("allow-ac");
        }
      }, 25);
    },
    hideAc() {
      document.getElementById("app").classList.remove("allow-ac");
    },
    showPopup(title) {
      this.popup.title = title;
      this.popup.content = POPUP_CONTENT[title.toLowerCase()];
      this.popup.show = true;
    },
    boxClicked(state) {
      if (this.getCount(state)) {
        if (this.filters.diseaseState === state) {
          this.resetState();
        } else {
          setTimeout(() => {
            this.zoomBox(state);
          }, 300);
          this.filters.diseaseState = state;
          this.updateData();
        }
      }
    },
    resetState() {
      this.resetZoom(this.filters.diseaseState);
      this.filters.diseaseState = "";
      this.updateData();
    },
    getBoxClass(state) {
      const ENABLED = !this.getCount(state) ? "disabled" : "enabled";
      const ZOOMED =
        state === this.filters.diseaseState ? "zoomed" : "unzoomed";
      return `${ENABLED} ${ZOOMED}`;
    },
    getBoxStyle(state) {
      let _style = "";
      const EL = this.$refs.zoomWindow;
      if (EL) {
        const STATE_COUNT = this.getCount(state);
        const W = EL.offsetWidth / 5;
        const H = EL.offsetHeight / 3;
        const MAX = W > H ? H : W;
        const RATIO = STATE_COUNT / this.maxStateCount;
        const ADJUSTMENT = 0.5 * RATIO + 0.5;
        const SIZE = MAX * ADJUSTMENT;
        _style += `width: ${SIZE}px; height: ${SIZE}px; `;
      }
      return _style;
    },
    getCount(state) {
      if (this.stateData[state]) {
        return this.stateData[state].length;
      } else {
        return 0;
      }
    },
    topBubbleZoom(mode) {
      console.log("mode", mode);
      this.topBubbleMode = mode;
    },
    async loadData() {
      this.data = await DataService.loadData();
      this.ready = true;
    },
    updateHeaders() {
      this.headers = [];
      this.tableColumns.forEach((header) => {
        if (header._enabled) {
          this.headers.push({
            text: header.text,
            align: "left",
            sortable: true,
            value: header.value,
          });
        }
      });
    },
    init() {
      window.$app = this;
      if (!this.ready) {
        setTimeout(this.init, 250);
      } else {
        this.resize();
        this.initTableHeaders();
        this.updateData();
        setTimeout(() => {
          this.setTrace();
        }, 1000);
      }
    },
    setTrace() {
      let mtop = 99999;
      let mleft = 99999;
      let mright = 0;
      let mbottom = 0;
      document.querySelectorAll(".bc-box").forEach((box) => {
        let { top, left, right, bottom } = box.getBoundingClientRect();
        top = Math.round(top);
        left = Math.round(left);
        right = Math.round(right);
        bottom = Math.round(bottom);
        if (top < mtop) mtop = top;
        if (left < mleft) mleft = left;
        if (bottom > mbottom) mbottom = bottom;
        if (right > mright) mright = right;
      });
      let mwidth = mright - mleft;
      let mheight = mbottom - mtop;
      const rel = document.getElementById("bubble-contents");
      mtop -= rel.getBoundingClientRect().top;
      mleft -= rel.getBoundingClientRect().left;
      this.btStyle = `z-index: 999; position: absolute; pointer-events: none; top: ${mtop}px; left: ${mleft}px; width: ${mwidth}px; height: ${mheight}px;`;
      setTimeout(() => {
        this.introJS.setOption("dontShowAgain", true);
        this.introJS.setOption("scrollToElement", false);
        this.introJS.start();
      }, 50);
    },
    showHelp() {
      this.introJS.setOption("dontShowAgain", false);
      this.introJS.start();
      setTimeout(() => {
        this.introJS.setOption("dontShowAgain", true);
      }, 50);
    },
    initTableHeaders() {
      const COMPLETED_HEADERS = [];
      this.tableColumns = JSON.parse(JSON.stringify(DEFAULT_HEADERS));
      this.tableColumns.forEach((col) => {
        col._enabled = true;
        COMPLETED_HEADERS.push(col.value);
      });
      this.data.tableData.forEach((header) => {
        if (
          !COMPLETED_HEADERS.includes(header) &&
          !header.includes("Key Research")
        ) {
          this.tableColumns.push({
            text: header,
            value: header,
            _enabled: false,
          });
        }
      });
      this.updateHeaders();
    },
    resetFilters() {
      this.data.assets.list.forEach((el) => {
        this.filters.assets[el] = false;
      });
      this.data.studyDesigns.list.forEach((el) => {
        this.filters.studyDesigns[el] = false;
      });
      this.data.regions.list.forEach((el) => {
        this.filters.regions[el] = false;
      });
      this.data.studyTypes.list.forEach((el) => {
        this.filters.studyTypes[el] = false;
      });
      this.data.owners.list.forEach((el) => {
        this.filters.owners[el] = false;
      });
      this.filters.search = "";
      this.updateData();
    },
    resize() {
      const APP = document.getElementById("app");
      const BOTTOM = document.getElementById("bottom");
      this.outerHeight = APP.offsetHeight - 64 - BOTTOM.offsetHeight;
      // const RIGHT = document.getElementById("right");
      // const LEFT_INSERT = document.getElementById("left-insert");
      // this.ht = this.outerHeight - LEFT_INSERT.offsetHeight;
      this.ht = this.outerHeight;
      // this.wd = APP.offsetWidth - RIGHT.offsetWidth;
      this.wd = "100%";
      this.updateData(true);
      setTimeout(this.setBarSize, 500);
      this.$forceCompute("tableHeight");
      this.$forceCompute("isMobile");
      this.animateFilters(!this.filtersOpen);
      this.updateHeaders();
    },
    setBarSize() {
      this.setBar1Size();
      this.setBar2Size();
      this.setBar3Size();
      this.setBar4Size();
      this.setBar5Size();
      this.$forceCompute("tableHeight");
      this.showBars = true;
    },
    setBar1Size() {
      const box1 = document.querySelectorAll(".bc-box-1")[0];
      const box4 = document.querySelectorAll(".bc-box-4")[0];
      const bar1 = document.getElementById("bar1");
      const start = box1.offsetLeft + box1.offsetWidth / 2;
      const end = box4.offsetLeft + box4.offsetWidth / 2;
      const width = end - start;
      bar1.style.left = start + "px";
      bar1.style.width = width + "px";
    },
    setBar2Size() {
      const box2 = document.querySelectorAll(".bc-box-2")[0];
      const box3a = document.querySelectorAll(".bc-box-3a")[0];
      const bar2 = document.getElementById("bar2");
      const startX = box2.offsetLeft + box2.offsetWidth / 2;
      const endX = box3a.offsetLeft + box3a.offsetWidth / 2;
      const startY = box2.offsetTop + box2.offsetHeight / 2;
      const endY = box3a.offsetTop + box3a.offsetHeight / 2;
      const width = endX - startX;
      const height = startY - endY;
      bar2.style.left = startX + "px";
      bar2.style.top = endY + "px";
      bar2.style.width = width + "px";
      bar2.style.height = height + "px";
    },
    setBar3Size() {
      const box2 = document.querySelectorAll(".bc-box-2")[0];
      const box3b = document.querySelectorAll(".bc-box-3b")[0];
      const bar3 = document.getElementById("bar3");
      const startX = box2.offsetLeft + box2.offsetWidth / 2;
      const endX = box3b.offsetLeft + box3b.offsetWidth / 2;
      const startY = box2.offsetTop + box2.offsetHeight / 2;
      const endY = box3b.offsetTop + box3b.offsetHeight / 2;
      const width = endX - startX;
      const height = endY - startY;
      bar3.style.left = startX + "px";
      bar3.style.top = startY + "px";
      bar3.style.width = width + "px";
      bar3.style.height = height + "px";
    },
    setBar4Size() {
      const box3a = document.querySelectorAll(".bc-box-3a")[0];
      const box4 = document.querySelectorAll(".bc-box-4")[0];
      const bar4 = document.getElementById("bar4");
      const startX = box3a.offsetLeft + box3a.offsetWidth / 2;
      const endX = box4.offsetLeft + box4.offsetWidth / 2;
      const startY = box3a.offsetTop + box3a.offsetHeight / 2;
      const endY = box4.offsetTop + box4.offsetHeight / 2;
      const width = endX - startX;
      const height = endY - startY;
      bar4.style.left = startX + "px";
      bar4.style.top = startY + "px";
      bar4.style.width = width + "px";
      bar4.style.height = height + "px";
    },
    setBar5Size() {
      const box3b = document.querySelectorAll(".bc-box-3b")[0];
      const box4 = document.querySelectorAll(".bc-box-4")[0];
      const bar5 = document.getElementById("bar5");
      const startX = box3b.offsetLeft + box3b.offsetWidth / 2;
      const endX = box4.offsetLeft + box4.offsetWidth / 2;
      const startY = box3b.offsetTop + box3b.offsetHeight / 2;
      const endY = box4.offsetTop + box4.offsetHeight / 2;
      const width = endX - startX;
      const height = startY - endY;
      bar5.style.left = startX + "px";
      bar5.style.top = startY - box3b.offsetHeight - 40 + "px";
      bar5.style.width = width + "px";
      bar5.style.height = height + "px";
    },
    checkFilter(prop, id) {
      if (this.checkIfFilterEmpty(prop)) {
        return true;
      }
      for (let filterNum in this.filters[prop]) {
        const filter = this.filters[prop][filterNum];
        if (this.filters[prop][filterNum]) {
          const filteredIds = this.data[prop].data[filter];
          if (filteredIds?.includes(id)) {
            return true;
          }
        }
      }
      return false;
    },
    checkIfFilterEmpty(prop) {
      const KEYS = Object.keys(this.filters[prop]);
      for (let i in KEYS) {
        const KEY = KEYS[i];
        if (this.filters[prop][KEY]) {
          return false;
        }
      }
      return true;
    },
    checkSearch(study) {
      if (!study || !this.filters.search) {
        return true;
      }
      const NAME_RAW = study.data["Common Name (Investigator)"] || "";
      const NAME = NAME_RAW.toLowerCase();
      const INVESTIGATOR =
        study.data["Principal Investigator"]?.toLowerCase() || "";
      const NCT_RAW = study.data["NCT number"] || "";
      const NCT = NCT_RAW.toLowerCase();
      const HAYSTACK = NAME + " " + NCT + " " + INVESTIGATOR;
      // const HAYSTACK = Object.values(study).join(" | ").toLowerCase();
      const NEEDLE = this.filters.search.toLowerCase();
      // console.log(HAYSTACK, NEEDLE);
      return !NEEDLE || HAYSTACK.includes(NEEDLE);
    },
    checkState(id) {
      const STATE = this.filters.diseaseState;
      if (!STATE) {
        return true;
      } else {
        return this.data.diseaseStates[STATE].includes(id);
      }
    },
    filterTableData() {
      this.tableData = [];
      this.stateData = {};
      this.data.studies.forEach((study) => {
        const STUDY = study.data;
        const ID = study.uniqueId;
        const A_CHECK = this.checkFilter("assets", ID);
        const SD_CHECK = this.checkFilter("studyDesigns", ID);
        const R_CHECK = this.checkFilter("regions", ID);
        const ST_CHECK = this.checkFilter("studyTypes", ID);
        const O_CHECK = this.checkFilter("owners", ID);
        const STATE_CHECK = this.checkState(ID);
        const SEARCH_CHECK = this.checkSearch(study);
        if (
          A_CHECK &&
          SD_CHECK &&
          R_CHECK &&
          ST_CHECK &&
          O_CHECK &&
          STATE_CHECK &&
          SEARCH_CHECK
        ) {
          this.tableData.push(STUDY);
          study.diseaseStates.forEach((state) => {
            if (!this.stateData[state]) {
              this.stateData[state] = [];
            }
            this.stateData[state].push(ID);
          });
        }
      });
      this.maxStateCount = 0;
      for (let state in this.stateData) {
        const LENGTH = this.stateData[state].length;
        if (LENGTH > this.maxStateCount) {
          this.maxStateCount = LENGTH;
        }
      }
      if (this.maxStateCount === 0) {
        this.maxStateCount = 1;
      }
      if (!this.tableData.length) {
        setTimeout(() => {
          this.resetState();
        }, 500);
      }
      this.$forceCompute("tableHeight");
    },
    updateSearch() {
      if (this.searchDebounceTimeout) clearTimeout(this.searchDebounceTimeout);
      this.searchDebounceTimeout = setTimeout(() => {
        this.updateData();
      }, 500);
    },
    updateData(passive) {
      if (!passive) {
        this.study = null;
      }
      this.chartReady = false;
      this.filterTableData();
      this.setAcItems();
      setTimeout(() => (this.chartReady = true), 1);
      this.showBars = false;
      setTimeout(this.setBarSize, 500);
    },
    setAcItems() {
      const AC_ITEMS = [];
      function addToItems(str) {
        str = DataService.decodeHtml(str);
        if (str && !AC_ITEMS.includes(str)) {
          AC_ITEMS.push(str);
        }
      }
      this.tableData.forEach((study) => {
        // Object.values(study).forEach((val) => addToItems(val));
        addToItems(study["NCT number"]);
        addToItems(study["Common Name (Investigator)"]);
        addToItems(study["Principal Investigator"]);
      });
      AC_ITEMS.sort();
      this.acItems = AC_ITEMS;
    },
    onDragged({ deltaX, first, last }) {
      if (first) {
        this.isDragging = true;
        this.dragStart = this.filtersLeft;
        return;
      }
      if (last) {
        this.isDragging = false;
        const DIF = Math.abs(this.dragStart - this.filtersLeft);
        if (DIF > 20) {
          if (this.filtersOpen) {
            this.closeFilters();
          } else {
            this.openFilters();
          }
        }
        return;
      }
      let left = this.filtersLeft + deltaX;
      const MIN = 0 - this.$refs.filters.clientWidth;
      if (left > 0) {
        left = 0;
      } else if (left < MIN) {
        left = MIN;
      }
      this.filtersLeft = left;
    },
    animateFilters(close, cb) {
      clearTimeout(this.animTimeout);
      const MAX_DURATION = 250;
      let maxDistance = this.$refs.filters ? this.$refs.filters.offsetWidth : 0;
      const OFFSET_LEFT = this.$refs.filters
        ? this.$refs.filters.offsetLeft
        : 0;
      const FPS = 30;
      const INTERVAL = 1000 / FPS;
      let STEP_DISTANCE = maxDistance / ((MAX_DURATION / 1000) * FPS);
      const THIS = this;
      let distance;
      if (close) {
        distance = maxDistance - OFFSET_LEFT;
      } else {
        distance = 0 - OFFSET_LEFT;
      }
      const STEPS = Math.ceil(distance / STEP_DISTANCE);
      let counter = 0;
      anim();
      function anim() {
        counter++;
        if (close) {
          const MIN = 0 - maxDistance;
          let left = THIS.filtersLeft;
          left -= STEP_DISTANCE;
          if (left < MIN) {
            left = MIN;
          }
          THIS.filtersLeft = left;
        } else {
          let left = THIS.filtersLeft;
          left += STEP_DISTANCE;
          if (left > 0) {
            left = 0;
          }
          THIS.filtersLeft = left;
        }
        if (counter < STEPS) {
          THIS.animTimeout = setTimeout(anim, INTERVAL);
        } else {
          if (cb) {
            cb();
          }
        }
      }
    },
    closeFilters() {
      this.animateFilters(true, () => {
        this.filtersOpen = false;
      });
    },
    openFilters() {
      this.animateFilters(false, () => {
        this.filtersOpen = true;
      });
    },
    toggleFilters() {
      if (this.filtersOpen) {
        this.closeFilters();
      } else {
        this.openFilters();
      }
    },
    stopDrag(e) {
      console.log(e);
      // e.stopPropagation();
    },
    resetZoom(boxRef) {
      const box = this.$refs[boxRef];
      box.style.transform = "";
    },
    zoomBox(boxRef) {
      const box = this.$refs[boxRef];
      const win = this.$refs["zoomWindow"];
      const boxSize = Math.max(box.offsetHeight, box.offsetWidth);
      const winSize = Math.max(win.offsetHeight, win.offsetWidth);
      const scale = Math.ceil(winSize / box.offsetHeight) * 1.5;
      const boxLeft = (winSize - boxSize) / 2 - box.offsetLeft;
      box.style.transform = `translate(${boxLeft}px, 0) scale(${scale})`;
    },
    tableRowClass(item) {
      let rowClass = "state-" + this.filters.diseaseState;
      if (this.study && item.uniqueId === this.study.uniqueId) {
        rowClass += " highlighted-row";
      }
      return rowClass;
    },
    tableRowClicked(row) {
      for (let i in this.data.studies) {
        let study = this.data.studies[i];
        let id = study.uniqueId;
        if (row.uniqueId === id) {
          this.study = study;
          this.studyPopup = true;
          return false;
        }
      }
    },
    closeStudyPopup() {
      this.studyPopup = false;
      this.study = null;
    },
    downloadTable() {
      // console.log(this.tableData);
      const TABLE = [];
      const HEADERS = [];
      this.data.tableData.forEach((header) => {
        HEADERS.push(header);
      });
      TABLE.push(HEADERS);
      this.tableData.forEach((row) => {
        const ROW = [];
        HEADERS.forEach((header) => {
          console.log(header, row[header]);
          ROW.push(row[header]);
        });
        TABLE.push(ROW);
      });
      this.saveData(TABLE, "data.csv");
    },
    saveData(data, fileName) {
      let a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      let rows = [];
      data.forEach((row) => {
        rows.push('"' + row.join('","') + '"');
      });
      let json = rows.join("\r\n"),
        blob = new Blob([json], { type: "octet/stream" }),
        url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
      setTimeout(() => {
        a.remove();
      }, 500);
    },
  },
};
</script>

<style lang="scss">
.v-main {
  height: calc(100vh - 64px);
  .v-main__wrap {
    overflow-x: hidden;
  }
}
.v-main > .v-main__wrap > .container {
  padding: 0 !important;
  max-width: initial;
  #bottom {
    width: 100vw;
    margin-left: 0;
    height: calc(30vh - 80px);
    overflow-y: auto;
  }
  #main {
    display: flex;
    justify-content: space-between;
    height: calc(70vh - 56px);
    margin-top: 0 !important;
    #left {
      font-family: "Nunito Sans", Helvetica, Arial, sans-serif;
      // width: calc(100vw - 320px);
      padding: 0;
      // width: 58vw;
      // min-width: 58vw;
      width: 100%;
      display: block;
      position: relative;
      #left-insert {
        position: absolute;
        z-index: 10;
        top: 0;
        left: 0;
        background-color: #333333;
        width: 80%;
        max-width: 480px;
        margin: 0 0 2px 0;
        color: white;
        #left-insert-content {
          &::-webkit-scrollbar {
            width: 12px;
          }
          &::-webkit-scrollbar-track {
            background: none;
          }
          &::-webkit-scrollbar-thumb {
            background-color: #555555;
            border-radius: 20px;
            border: 2px solid #333333;
          }
          max-height: calc(100vh - 65px);
          padding: 15px 25px 0 20px;
          overflow-y: auto;
        }
        h2 {
          user-select: none;
          margin-bottom: 10px;
          font-weight: 700;
          line-height: 115%;
          text-align: center;
        }
        .label {
          user-select: none;
          color: #22577e;
          font-size: 116.667%;
          font-weight: 900;
        }
        #left-button {
          user-select: none;
          height: 100%;
          background-color: #333333;
          width: 24px;
          position: absolute;
          top: 0;
          right: -24px;
          border-left: 1px solid #ccc;
          justify-content: center;
          align-items: center;
          display: flex;
          cursor: pointer;
          #rotated-label {
            -webkit-transform: rotate(-90deg);
            -moz-transform: rotate(-90deg);
            transform: rotate(-90deg);
            filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
          }
        }
        #left-button:hover {
          background-color: #7c7e7b;
        }
      }
    }
    #right {
      font-family: "Nunito Sans", Helvetica, Arial, sans-serif;
      width: 42vw;
      min-width: 42vw;
      overflow-y: auto;
      background-color: #00a0de;
      height: 70vh;
      margin-right: 4px;
      padding-bottom: 30px;
      h2 {
        margin-bottom: 1em;
        font-weight: 700;
        line-height: 115%;
        text-align: center;
      }
      .label {
        color: #22577e;
        font-size: 116.667%;
      }
      .value {
        margin-top: 4px;
        font-weight: 900;
        font-size: 133%;
        color: black;
      }
    }
  }
}
.v-application--is-ltr .v-slider--horizontal {
  .v-slider__tick .v-slider__tick-label {
    transform: translateX(-50%) !important;
    font-size: 12px;
  }
}
.v-data-table {
  width: 100%;
  z-index: 5;
  .v-data-table-header th.sortable .v-data-table-header__icon {
    display: flex;
  }
  .v-data-table__wrapper > table {
    thead {
      tr > th {
        &:first-child {
          padding-left: 40px;
        }
        // min-width: 180px;
        overflow-y: hidden;
        // overflow-x: hidden;
        line-height: 115%;
        background-color: #003479 !important;
        color: white !important;
      }
    }
    tbody {
      tr:nth-of-type(odd) {
        background-color: #d9e1eb;
      }
      tr > td {
        &:first-child {
          padding-left: 40px;
        }
        line-height: 115%;
        font-size: 12px !important;
        cursor: pointer;
        div {
          max-height: 2em;
          white-space: nowrap;
          // max-width: 200px;
          // min-width: 120px;
          overflow-x: hidden;
          overflow-y: hidden;
          text-overflow: ellipsis;
          padding: 6px 2px;
          font-size: 10px;
          &::-webkit-scrollbar {
            width: 6px;
          }
          &::-webkit-scrollbar-track {
            background: none;
            margin: 2px 0;
          }
          &::-webkit-scrollbar-thumb {
            background-color: #acaeab66;
            border-radius: 6px;
          }
          &:hover::-webkit-scrollbar-thumb {
            background-color: #acaeab;
          }
        }
      }
      .highlighted-row {
        background-color: #00a0de !important;
      }
    }
  }
}
#bubble-contents {
  // position: absolute;
  // top: 50%;
  width: 100%;
  height: 100%;
  text-align: center;
  overflow: hidden;
  transition: all 500ms ease;
  // pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #c0e7f8;
  .bc-box {
    color: white;
    font-weight: bold;
    font-size: 120%;
    line-height: 105%;
    padding: 5px;
    margin: 5px;
    position: relative;
    z-index: 1;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    opacity: 0.9;
    border-radius: 100%;
    &.enabled {
      cursor: pointer;
    }
    &.enabled:hover {
      opacity: 1;
      border-width: 3px;
    }
    &.disabled {
      color: #aaaaaa;
      background-color: #666666;
      cursor: default;
    }
    &.zoomed {
      transform: scale(3);
      opacity: 1;
      z-index: 2;
      transition: all 250ms ease;
      & div {
        transition: all 125ms ease;
        opacity: 0;
      }
    }
    &.unzoomed {
      transform: scale(1);
      opacity: inherit;
      transition: all 250ms ease;
      & div {
        transition: all 500ms ease;
        opacity: 1;
      }
    }
  }
  .bc-box-1 {
    width: 100px;
    height: 100px;
    background-color: #6ebe45;
  }
  .bc-box-2 {
    width: 100px;
    height: 100px;
    background-color: #702e38;
  }
  .bc-box-3 {
    border: none;
    display: inline-block;
    & > div {
      justify-content: center;
      display: flex;
    }
  }
  .bc-box-3a {
    width: 100px;
    height: 100px;
    margin-bottom: 50px;
    background-color: #1d74b9;
    display: flex;
  }
  .bc-box-3b {
    width: 100px;
    height: 100px;
    margin-top: 50px;
    background-color: #00a0de;
    display: flex;
  }
  .bc-box-4 {
    width: 100px;
    height: 100px;
    background-color: #003478;
  }
  #study-list {
    color: white;
    font-weight: bold;
    z-index: 3;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    height: 100%;
    width: 100vw;
    transition: all 250ms ease;
    &.visible {
      opacity: 1;
    }
    #study-list-close {
      position: absolute;
      top: -1px;
      left: 20px;
    }
    #study-list-content {
      background-color: #ffffff88;
      overflow-y: auto;
      height: calc(100% - 44px);
      width: 100vw;
      padding: 6px 10px 10px 34px;
      margin-top: 42px;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      .study-card {
        background-color: #ffffff;
        box-shadow: 2px 2px 4px #00000066;
        text-align: left;
        margin: 8px 12px 8px 4px;
        padding: 8px;
        border-radius: 8px;
        width: calc(50% - 16px);
        .name {
          color: #333653;
          font-size: 20px;
          line-height: 115%;
          font-weight: 1000;
          display: block;
        }
        .type {
          color: #68a389;
          font-size: 13px;
          font-weight: normal;
          display: block;
        }
        cursor: pointer;
        &:hover {
          background-color: white;
        }
        &.highlighted-row {
          background-color: #00a0de !important;
          cursor: default;
          .name,
          .type {
            color: white !important;
          }
          // border-color: black;
          &.state-LPC {
            background-color: #6ebd44 !important;
          }
          &.state-BCR {
            background-color: #6f2e38 !important;
          }
          &.state-mCRPC {
            background-color: #1c75bc !important;
          }
          &.state-nmCSPC {
            background-color: #00a0df !important;
          }
          &.state-mCSPC {
            background-color: #003479 !important;
          }

          // LPC: "#6EBD44",
          // BCR: "#6F2E38",
          // mCRPC: "#1C75BC",
          // nmCSPC: "#00A0DF",
          // mCSPC: "#003479"
        }
      }
    }
  }
  #bars {
    div {
      position: absolute;
    }
    #bar1 {
      height: 6px;
      background-color: black;
    }
  }
}
.diag-up {
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 100 100'><path d='M0 96 L96 0 L100 2 L2 100' fill='black' /></svg>");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%, auto;
}
.diag-down {
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 100 100'><path d='M0 96 L96 0 L100 2 L2 100' fill='black' /></svg>");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%, auto;
  transform: scaleX(-1);
}
#left-logo {
  width: 200px;
  height: 56px;
  background-image: url(../assets/logo-left-v2.png);
  background-size: 200px 43px;
}
#right-logo {
  width: 166px;
  height: 56px;
  background-image: url(../assets/logo-right.png);
  background-size: contain;
}
header {
  z-index: 100;
}
.popup-title {
  background-color: #003478;
  color: white;
  font-size: 1.5em;
  border-radius: 4px 4px 0 0;
  span {
    word-break: normal;
  }
}
.popup-text {
  padding-top: 1em !important;
  .scroller {
    margin: -1em;
    padding: 1em;
    overflow-y: auto;
    max-height: calc(100vh - 180px);
    li {
      margin-left: -0.5em;
      padding-bottom: 1em;
      line-height: 105%;
    }
  }
  .mobile-scroller {
    max-height: calc(100vh - 70px);
  }
}
#bottom-buttons {
  position: absolute;
  z-index: 6;
  right: 26px;
  bottom: 68px;
}
#help-btn {
  margin: 10px 10px 15px 0;
}
#table-settings {
  margin: 10px 10px 15px 0;
}
#table-download {
  margin: 10px 10px 15px 0;
}
.inline-checkbox {
  display: inline-block !important;
  min-width: 50% !important;
}
#breadcrumbs {
  position: absolute;
  top: 9px;
  left: 60px;
}
#bottom-data-table .v-icon {
  font-size: 18px;
  display: inline-block;
  color: white;
  margin-left: 5px;
}
#bottom-data-table,
#bottom-data-table .v-data-table__wrapper table {
  max-width: 100vw;
}
.v-select__selections {
  font-size: 12px !important;
  line-height: 12px !important;
  .v-select.v-input--dense .v-chip {
    padding: 6px 8px 4px;
  }
  input {
    font-size: 12px !important;
    line-height: 12px !important;
    padding: 0 !important;
    margin: 0 !important;
  }
}
.v-select.v-input--dense .v-select__selection--comma {
  margin: 0 4px 0 0 !important;
}
#filter-bar {
  height: 70px !important;
  .v-toolbar-content {
    height: 70px !important;
  }
  .v-select__slot input {
    font-size: 12px;
  }
}

@media only screen and (max-width: 900px) {
  #filter-bar {
    height: 56px !important;
    .v-toolbar-content {
      height: 56px !important;
    }
  }
  .v-main > .v-main__wrap > .container {
    #main {
      height: calc(50vh - 56px);
      .bc-box-contents {
        font-size: 60%;
        line-height: 115%;
      }
    }
    #bottom {
      height: calc(50vh - 70px);
      .v-data-table .v-data-table__wrapper > table tbody tr > td:first-child,
      .v-data-table .v-data-table__wrapper > table thead tr > th:first-child {
        padding-left: 10px !important;
      }
      .v-data-table .v-data-table__wrapper > table tbody tr > td {
        max-width: 30vw;
      }
    }
  }
  #left-logo {
    width: 35vw;
    background-size: contain;
    background-position: center;
  }
  #header-bar button.v-btn {
    margin-right: 0 !important;
  }
  #header-bar button.v-btn {
    font-size: 11px !important;
  }
  #right-logo {
    width: 25vw;
    background-size: contain;
    background-position: center;
  }
}

.full-width {
  width: 100% !important;
}
</style>
