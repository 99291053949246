<template>
  <v-col :class="colClass || ''">
    <div class="sd-card">
      <div class="sd-card-title" v-html="cardTitle"></div>
      <div
        class="sd-card-content"
        v-html="cardContent"
        :class="cardClass"
      ></div>
    </div>
  </v-col>
</template>

<script>
export default {
  name: "StudyDetailsCard",
  props: ["title", "prop", "center", "colClass", "growContent"],
  data() {
    return {
      cardTitle: "",
      cardContent: "",
      cardClass: "",
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      const STUDY = this.$parent.study;
      const VAL = STUDY.data[this.prop];
      this.cardTitle = this.title || this.prop;
      if (VAL === undefined || VAL === null || VAL === "") {
        this.cardContent = "&nbsp;";
      } else {
        this.cardContent = VAL;
      }
      this.setCardClass();
    },
    setCardClass() {
      this.cardClass = "";
      if (this.center) {
        this.cardClass += " center";
      }
      if (this.growContent) {
        this.cardClass += " growContent";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.row {
  margin: 0;
  padding: 0;
  align-items: end;
}
.col {
  margin: 0 2px 5px;
  padding: 0;
}
.sd-card {
  border-radius: 8px 8px 0 0;
  background-color: white;
  .sd-card-title {
    width: 100%;
    background-color: #003479;
    border-radius: 8px 8px 0 0;
    line-height: 125%;
    color: white;
    text-transform: uppercase;
    text-align: center;
    font-size: 11px;
    font-weight: 500;
    padding: 8px 4px;
  }
  .sd-card-content {
    padding: 8px;
    font-size: 15px;
    line-height: 115%;
    &.center {
      text-align: center;
    }
  }
}
</style>
